import React, { useState } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Header = () => {
  const [navbarActive, setNavbarActive] = useState(false)

  const toggleNavbar = () => {
    setNavbarActive(!navbarActive)
  }

  const { logo } = useStaticQuery(graphql`
    {
      logo: file(name: { eq: "moelkky-online-icon" }) {
        childImageSharp {
          gatsbyImageData(height: 50, layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <header style={{ paddingBottom: "20px" }}>
      <nav className="navbar container">
        <div className="navbar-brand">
          <Link className="navbar-item" to={"/"}>
            <GatsbyImage
              image={logo.childImageSharp.gatsbyImageData}
              alt="Home"
              title="Home"
            />
          </Link>
          <button
            className={`is-hidden-desktop navbar-burger burger ${
              navbarActive ? "is-active" : ""
            } button is-borderless`}
            data-target="navMenu"
            onClick={toggleNavbar}
            onKeyDown={() => {}}
            tabIndex={0}
            aria-label="menu toggle"
          >
            <span />
            <span />
            <span />
          </button>
        </div>

        <div
          id="navbarMoelkky"
          className={`navbar-menu ${navbarActive ? "is-active" : ""}`}
        >
          <div className="navbar-start has-text-centered">
            <Link to={"/spielen/"} className="navbar-item">
              Spielen
            </Link>
            <Link to={"/regeln/"} className="navbar-item">
              Regeln
            </Link>
            <Link to={"/bedienung/"} className="navbar-item">
              Bedienung
            </Link>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
